module.exports = global.config = {
    url: {
        // client: "http://localhost:3000/", ///http://localhost:3000/
        // base: "http://localhost:8000/",
        // api: "http://localhost:8000/api/",
        ///////////////////////////////////
        // client: "https://dev-sso.ictangiang.vn/", ///https://dev-sso.ictangiang.vn/
        //base: "https://service.ictangiang.vn/",
        //api: "https://service.ictangiang.vn/api/",
        /////////////////////////////////////
        // client: "https://demo.ictangiang.vn/",
        // base: "https://service.ictangiang.vn/",
        // api: "https://service.ictangiang.vn/api/",
        // ///////////////////////////////////////
        client: "https://csdlxa.ictangiang.vn/",
        base: "https://proces.ictangiang.vn/",
        api: "https://proces.ictangiang.vn/api/",
        // rest of your translation object
    },
    key: {
        app: "7yp!F4m78Qp@2tnV",
        log: "HyLY@KwUS!2$$Nf8",
    },
    sso_ag: {
        client_key: "gvjPSi_Y1hpmznXh2k7Ns4h0HKga",
        client_secret: "uiRDd71_ppaw1fcwBkNpUo_Ogtwa",
    },
    sso_vneid: {
        client_key: "dvc-angiang-centralized-web",
        client_secret: "ba26cd98-dbe4-4e1f-afaf-271ac102638c",
    },
    cates: {
        ////default config cate global
        tbl_cate_ethnic: [],
        tbl_cate_forms_training: [],
        tbl_cate_informatics_certificate: [],
        tbl_cate_language_certificate: [],
        tbl_cate_nationality: [],
        tbl_cate_organize: [],
        tbl_cate_position_party: [],
        tbl_cate_position_trade_union: [],
        tbl_cate_position_union: [],
        tbl_cate_religion: [],
        tbl_product_cate: [],
        rsdt_cate_business: [],
        rsdt_cate_education: [],
        rsdt_cate_policy: [],
        rsdt_cate_relationship: [],
        rsdt_cate_status_household: [],
        rsdt_cate_status_people: [],
        rsdt_cate_prevent: [],
        rsdt_cate_evils: [],
        rsdt_cate_military: [],
        rsdt_cate_marriage: [],
        rsdt_cate_reason: [],
        rsdt_cate_clan: [],
        rsdt_cate_position_members: [],
        rsdt_cate_position_women: [],
        rsdt_cate_targets_subsidize: [],
        rsdt_cate_position_farmer: [],
        rsdt_type_achieve: [],
        agri_alike_tree: [],
        agri_type_tree: [],
        agri_form_circulate: [],
        agri_limit_circulate: [],
        dashboards: [],
        rsdt_cate_component_household: [],
    },
};
